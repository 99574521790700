import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const store = new Vuex.Store({
    state: {
        openId: localStorage.getItem('wxOpenId') || '',
        token : localStorage.getItem('token') || '',
		type:   localStorage.getItem('type')  || "" //score 消费分  balance权益
    },
    mutations: {
        setOpenId(state, value){
            state.openId = value
            localStorage.setItem('wxOpenId', value)
        },
        setToken(state, value){
            state.token = value
            localStorage.setItem('token', value)
        },
		setType(state,value){
			state.type=value
			localStorage.setItem("type",value)
		}
    },
    getters: {
        getOpenId: state => {
            return state.openId
        },
        getToken: state => {
            return state.token
        },
		getType:state=>{
			return state.type
		}
    }
})

export default store
