<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'App',
    mounted(){
      // //禁止微信浏览器改变字体大小
      // let WeixinJSBridge
      // if (typeof WeixinJSBridge == 'object' && typeof WeixinJSBridge.invoke == 'function') {
      //   handleFontSize()
      // } else {
      //   document.addEventListener('WeixinJSBridgeReady', handleFontSize, false);
      // }
      // function handleFontSize() {
      //   // 设置网页字体为默认大小
      //   WeixinJSBridge.invoke('setFontSizeCallback', {
      //     fontSize: 0
      //   })
      //   // 重写设置网页字体大小的事件
      //   WeixinJSBridge.on('menu:setfont', function () {
      //     WeixinJSBridge.invoke('setFontSizeCallback', {
      //       fontSize: 0
      //     })
      //   })
      // }
    }
  }
</script>

<style>
  body{
    margin: 0;
    padding: 0;
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
</style>
