/**
 *  Web唐明明
 *  匆匆数载恍如梦，岁月迢迢华发增。
 *  碌碌无为枉半生，一朝惊醒万事空。
 */

import VueRouter from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('@/pages/index'),
    name: 'Index',
    meta: { title: "抖火法律 - 注册"}
  },
  {
    path: '/app',
    component: () => import('@/pages/dowApp'),
    name: 'App',
    meta: { title: "抖火法律 - App下载"}
  },
  {
    path: '/wechat/pay',
    name: 'WechatPay',
    component: () => import('@/pages/wechatMini'),
    meta: { title: "抖火法律 - 微信支付"}
  },
  {
    path: '/agreement/:id',
    name: 'Agreement',
    component: () => import('@/pages/agreement'),
    meta: { title: "抖火法律" }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/pages/404.vue'),
    meta: { title: "抖火法律 - 404"}
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
